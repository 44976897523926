import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { Link } from "gatsby"
import QuoteForm from "../forms/QuoteForm"

export default function ButtonQuoteModal({ values }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  let formatedOptions = {}
  if (values?.productOptions?.length) {
    values.productOptions.forEach(({ name, value }) => {
      formatedOptions[name] = value
    })
  }

  return (
    <>
      <Link
        className="btn btn-primary font-weight-bold"
        to="#"
        onClick={handleShow}
      >
        Request a Custom Quote
      </Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request A Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuoteForm
            items={[
              {
                name: values?.product?.name || "",
                quantity: values?.quantity || 0,
                options: formatedOptions,
              },
            ]}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
