import React from "react"
import DefaultBG from "@images/pexels-curtis-adams-3773583.jpeg"

const PageHeading = ({ bgImage, children }) => {
  console.log("bgImage", bgImage)
  return (
    <div className="page-heading">
      {children}
      <div
        className="page-heading-bg"
        style={{
          backgroundImage: `url(${
            bgImage !== undefined ? bgImage : DefaultBG
          })`,
        }}
      />
    </div>
  )
}

export default PageHeading
