import React from "react"
import RequestQuoteForm from "@webriq/gatsby-webriq-form"

export default function QuoteForm({ items = [] }) {
  return (
    <>
      <RequestQuoteForm
        method="POST"
        data-form-id="569de2ad-5717-4560-a8af-0e2e4be4742b"
        name="Quote Form"
        webriq="true"
        data-thankyou-url="/thank-you"
      >
        {/*{items.map((el, index) => {
          const opt = Object.entries(el.options).map(
            ([_key, value]) =>
              `${_key}: ${value.length ? value.toString() : value}`
          )
          const val = `name: ${el.name}, quantity: ${el.quantity}, options: ${opt}`
          return (
            <textarea
              key={index}
              name={`product${index + 1}`}
              className="d-none"
              defaultValue={val}
            />
          )
        })}*/}
        <div className="form-group">
         <label htmlFor="fullname">Select Residential or Commercial</label>
         <select className="form-control " name="Residential/Commercial">
   
    <option value="Residential" >Residential</option>
    <option value="Commercial" >Commercial</option>
   
  </select>
        </div>
        <div className="form-group">
          <label htmlFor="fullname">Fullname</label>
          <input
            id="fullname"
            type="text"
            name="Full Name"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="Email Address"
            className="form-control"
            required
          />
        </div>
        {/*<div className="form-group">
          <label htmlFor="zipCode">Zip Code</label>
          <input
            id="zipCode"
            type="number"
            name="zipCode"
            className="form-control"
            required
          />
        </div>*/}
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            id="phone"
            type="number"
            name="Phone Number"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">
            What design/product are you interested in?
          </label>
          <textarea
            name="What design/product are you interested in?"
            className="form-control"
            id="message"
            rows="4"
          ></textarea>
        </div>
        <div className="mt-3 webriq-recaptcha" />
        <div className="mt-4">
          <button
            id="submit"
            type="submit"
            className="btn btn-primary text-uppercase"
          >
            Send Quote
          </button>
        </div>
      </RequestQuoteForm>
    </>
  )
}
